<template>
  <component
    :is="renderedComponent.component"
    v-if="renderedComponent"
    :field="field"
    :values="values"
    :errors="errors"
    @update="$emit('update', $event)"
    @error="$emit('error', $event)"
    @success="$emit('success', $event)"
  />
</template>

<script >
import { computed, defineAsyncComponent, ref } from '@vue/composition-api';
const AddressInput = defineAsyncComponent(() => import('@/components/AddressInput.vue'));
const Checkbox = defineAsyncComponent(() => import('@/components/Checkbox.vue'));
const DataTable = defineAsyncComponent(() => import('@/components/data-table/DataTable.vue'));
const DatePicker = defineAsyncComponent(() => import('@/components/DatePicker.vue'));
const DocumentViewer = defineAsyncComponent(() => import('@/components/DocumentViewer.vue'));
const Dropdown = defineAsyncComponent(() => import('@/components/Dropdown.vue'));
const Editor = defineAsyncComponent(() => import('@/components/wysiwyg/Editor.vue'));
const EmailInput = defineAsyncComponent(() => import('@/components/EmailInput.vue'));
const FileUpload = defineAsyncComponent(() => import('@/components/FileUpload.vue'));
const FormattedText = defineAsyncComponent(() => import('@/components/FormattedText.vue'));
const Grid = defineAsyncComponent(() => import('@/components/Grid.vue'));
const IBanInput = defineAsyncComponent(() => import('@/components/IBanInput.vue'));
const IFrameField = defineAsyncComponent(() => import('@/components/IFrameField.vue'));
const NumberInput = defineAsyncComponent(() => import('@/components/NumberInput.vue'));
const PasswordInput = defineAsyncComponent(() => import('@/components/PasswordInput.vue'));
const PaymentGateway = defineAsyncComponent(() => import('@/components/payment-gateway/PaymentGateway.vue'));
const RadioBox = defineAsyncComponent(() => import('@/components/RadioBox.vue'));
const ESignature = defineAsyncComponent(() => import('@/components/ESignature.vue'));
const TariffComparison = defineAsyncComponent(() => import('@/components/TariffComparison.vue'));
const TermsConditionsInput = defineAsyncComponent(() => import('@/components/TermsConditionsInput.vue'));
const TextArea = defineAsyncComponent(() => import('@/components/TextArea.vue'));
const TextInput = defineAsyncComponent(() => import('@/components/TextInput.vue'));
const Tile = defineAsyncComponent(() => import('@/components/Tile.vue'));
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const fieldList = ref([{
    type: 'address',
    component: AddressInput
  }, {
    type: 'checkbox',
    component: Checkbox
  }, {
    type: 'data-table',
    component: DataTable
  }, {
    type: 'date',
    component: DatePicker
  }, {
    type: 'document',
    component: DocumentViewer
  }, {
    type: 'dropdown',
    component: Dropdown
  }, {
    type: 'e-signature',
    component: ESignature
  }, {
    type: 'email-input',
    component: EmailInput
  }, {
    type: 'file-upload',
    component: FileUpload
  }, {
    type: 'html-editor',
    component: Editor
  }, {
    type: 'iban',
    component: IBanInput
  }, {
    type: 'number-input',
    component: NumberInput
  }, {
    type: 'password',
    component: PasswordInput
  }, {
    type: 'text',
    component: FormattedText
  }, {
    type: 'radio-box',
    component: RadioBox
  }, {
    type: 'toc',
    component: TermsConditionsInput
  }, {
    type: 'text-area',
    component: TextArea
  }, {
    type: 'text-input',
    component: TextInput
  }, {
    type: 'tariff-comparison',
    component: TariffComparison
  }, {
    type: 'iframe',
    component: IFrameField
  }, {
    type: 'payment-gateway',
    component: PaymentGateway
  }, {
    type: 'grid',
    component: Grid
  }, {
    type: 'tile',
    component: Tile
  }]);
  const renderedComponent = computed(() => fieldList.value.find(field => field.type === props.field.type));
  return {
    renderedComponent
  };
};
export default __sfc_main;
</script>

