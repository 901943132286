import filtrex from 'filtrex';
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class ArrayFilter {
    parse(data, values) {
        const { value } = parseExpression(data.array, values, 'strip');
        const array = JSON.parse(value);

        const filteredArray = array.filter(element => {
            if (this.isCriteriaSatisfied(data, values, element)) {
                return element;
            }
        })[0];
        return {
            value: filteredArray,
            isPlainText: true
        };
    }

    isCriteriaSatisfied(data, values, element) {
        const operator = data.query.condition === 'All' ? 'and ' : ' or ';
        const expression = this.parseQueryToExpression(data.query.properties, values, element, operator);
        const evaluator = filtrex.compileExpression(expression);
        return evaluator({});
    }

    parseQueryToExpression(properties, values, element, operator) {
        let expression = '';
        for (let i = 0; i < properties.length; i++) {
            const variable = element[properties.name];
            const value = properties.value;
            expression += this.parseQueryOperation(variable, properties[i].query.operator, value, operator);
        }
        return expression.slice(0, -4);
    }

    parseQueryOperation(variable, operation, value, operator) {
        switch (operation) {
        case 'equals':
            return '"' + variable + '"' + ' == ' + '"' + value + '" ' + operator;
        case 'does not equal':
            return '"' + variable + '"' + ' != ' + '"' + value + '" ' + operator;
        }
    }

};
