import ConditionChecker from '@/helpers/ConditionChecker';
import { parseExpression, parseTagAttribute } from './ExpressionParser';
import { useApplicationStore } from '@/store/applicationStore';
import { pinia } from '@/plugins/pinia';

export default new class FormRenderer {
    check(data, values) {
        for (let section of data.sections) {
            section.show = !!ConditionChecker.isConditionSatisfied(section.condition, values);
            for (const column of section.fields) {
                for (const field of column) {
                    if (field.type === 'grid') {
                        
                        const cellElements = field?.properties?.basic?.cells?.data?.flatMap(row => row.filter(
                            cell => cell?.properties?.basic?.element?.type
                        ));
                        cellElements.forEach(cell => {
                            this.renderForm(cell.properties.basic.element, values, section.show);
                        });
                    }
                    this.renderForm(field, values, section.show);
                }
            }
        }
        return data;
    }

    /**
     * Parses the raw values of a field and update its values with parsed value
     * @param {object} field 
     * @param {object} values Contains parsed values of all fields
     * @param {boolean} isSectionVisible
     */
    renderForm(field, values, isSectionVisible = true) {
        field.show = !!ConditionChecker.isConditionSatisfied(field.condition, values);
        const fieldBasicProperties = field?.properties?.basic;
        const validationProperties = field?.properties?.validation;
        const fieldValidationProperties = field?.properties?.validation;

        if (field.id) {
            const applicationStore = useApplicationStore(pinia);
            const checkFieldValueIgnored = fieldBasicProperties?.isIgnoreHiddenFieldValueDynamic ? (parseExpression(fieldBasicProperties?.ignoreHiddenFieldValue, values, 'strip')).value : fieldBasicProperties?.ignoreHiddenFieldValue;
            const isFieldValueIgnored = checkFieldValueIgnored ? !(isSectionVisible && field.show) : false;
            applicationStore.updateIgnoredVariable({
                type: 'field',
                value: field.id
            }, isFieldValueIgnored);
        }

        if (!fieldBasicProperties) {
            return;
        }

        if (field.type === 'text' || field.type === 'toc') {
            const parsedLinkText = this.parseDynamicLinks(fieldBasicProperties.rawText, values);
            fieldBasicProperties.text = (parseExpression(parsedLinkText, values, 'skip')).value;
            if (fieldBasicProperties.text && fieldBasicProperties.text.includes('{') && fieldBasicProperties.text.includes('}')) {
                fieldBasicProperties.text = fieldBasicProperties.text.replace(/\{([^}]+)\}/g, content => `{${content.replace(/"/g, '')}}`);
                fieldBasicProperties.text = fieldBasicProperties.text
                    .replaceAll('{', '')
                    .replaceAll('}', '');
            }
            const images = fieldBasicProperties.text.match(/(\bdata:image\/png;base64\S+\S+)/ig);
            if (images?.length) {
                images.forEach(image => {
                    fieldBasicProperties.text = fieldBasicProperties.text.replace(image, `<img src="${image}" class="var-img" width="340" height="150"  alt=""/>`);
                });
            }

        } else if (field.type === 'data-table') {

            let data = [];

            fieldBasicProperties.headers = [];
            fieldBasicProperties.data = [];

            for (const row of fieldBasicProperties.columns) {
                fieldBasicProperties.headers.push({
                    field: row.label,
                    label: row.label,
                    visible: row.visible
                });

                data.push({
                    label: row.label, value: (parseExpression(row.value, values, 'strip')).value
                });
            }

            let orderedData = [];
            data.forEach((row, index) => {
                let singleRow = {};
                row.value = row.value.replace(/<[^>]*>?/gm, '').trim();
                row.value = row.value.replace('{', '');
                row.value = row.value.replace('}', '');

                const rowValues = row.value.split('",');
                rowValues.forEach((value, i) => {
                    if (typeof singleRow[i] === 'undefined') {
                        singleRow[i] = {};
                    }
                    singleRow[i][row.label] = value.replaceAll('"', '');
                });
                orderedData.push(singleRow);
            });

            let groupedData = [];

            for (let i = 0; i < orderedData.length; i++) {
                for (const order in orderedData[i]) {
                    if (typeof groupedData[order] === 'undefined') {
                        groupedData[order] = [];
                    }
                    groupedData[order].push(orderedData[i][order]);
                }
            }

            groupedData.forEach(d => {
                fieldBasicProperties.data.push(d.reduce(((r, c) => Object.assign(r, c)), {}));
            });
        } else if (field.type === 'e-signature') {
            field.value = values[field.id];
        } else if (field.type === 'payment-gateway') {
            const { payload, serviceProvider } = fieldBasicProperties;
            if (serviceProvider === 'Element') {
                if (payload && Object.keys(payload).length) {
                    fieldBasicProperties.payload = {
                        ...payload,
                        quoteId: payload.quoteId ? (parseExpression(payload.quoteId, values, 'strip')).value : '',
                        signedUrl: payload.signedUrl ? (parseExpression(payload.signedUrl, values, 'strip')).value : '',
                        environment: payload.environment ? (parseExpression(payload.environment, values, 'strip')).value : ''
                    };
                }
            }
        } else if (field.type === 'tariff-comparison') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldExpressions?.highlightedTariffText) {
                fieldBasicProperties.highlightedTariffText = (parseExpression(fieldExpressions.highlightedTariffText, values, 'strip')).value;
            }
            if (fieldBasicProperties?.tariffs) {
                fieldBasicProperties.tariffs.forEach(tariff => {
                    const tariffExpressions = tariff.expressions;
                    if (tariff.isVisible === undefined) {
                        tariff.isVisible = true;
                    } else if (tariff?.isVisibilityDynamic && tariffExpressions?.isVisible) {
                        tariff.isVisible = (parseExpression(tariffExpressions.isVisible, values, 'strip')).value;
                    }
                    if (tariffExpressions?.isHighlighted) {
                        tariff.isHighlighted = (parseExpression(tariffExpressions.isHighlighted, values, 'strip')).value;
                    }
                    if (tariffExpressions?.title && tariff?.title) {
                        tariff.title = (parseExpression(tariffExpressions.title, values, 'strip')).value;
                    }
                    if (tariffExpressions?.price && tariff?.price) {
                        tariff.price = (parseExpression(tariffExpressions.price, values, 'strip')).value;
                    }
                    if (tariffExpressions?.description && tariff?.description) {
                        const parsedLinkText = this.parseDynamicLinks(tariffExpressions.description, values);
                        tariff.description = (parseExpression(parsedLinkText, values, 'skip')).value;
                    }
                    if (tariff.isButtonVisible === undefined) {
                        tariff.isButtonVisible = true;
                    } else if (tariff?.isButtonVisibilityDynamic && tariffExpressions?.isButtonVisible) {
                        tariff.isButtonVisible = (parseExpression(tariffExpressions.isButtonVisible, values, 'strip')).value;
                    }
                });
            }
        } else if (field.type === 'date') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.toggleTimePickerDynamic) {
                fieldBasicProperties.hasTimePicker = !!(parseExpression(fieldExpressions.hasTimePicker, values, 'strip')).value;
            }
            if(fieldBasicProperties?.isPlaceholderDynamic){
                fieldBasicProperties.placeholder = (parseExpression(fieldExpressions.placeholder, values, 'strip')).value;
            }
        } else if (field.type === 'iban') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.isIbanApiValidationToggleDynamic) {
                fieldBasicProperties.hasIbanApiValidation = !!(parseExpression(fieldExpressions.hasIbanApiValidation, values, 'strip')).value;
            }
        } else if (field.type === 'address'){
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.hasStateDynamic) {
                fieldBasicProperties.hasState = !!(parseExpression(fieldExpressions.hasState, values, 'strip')).value;
            }
            if (fieldBasicProperties?.hasDistrictDynamic) {
                fieldBasicProperties.hasDistrict = !!(parseExpression(fieldExpressions.hasDistrict, values, 'strip')).value;
            }                     
            if (fieldBasicProperties?.hasCountryDynamic) {
                fieldBasicProperties.hasCountry = !!(parseExpression(fieldExpressions.hasCountry, values, 'strip')).value;
            }
        } else if (field.type === 'file-upload'){
            const fieldExpressions = fieldValidationProperties?.expressions;
            if(fieldValidationProperties?.isCustomFormatDynamic){
                fieldValidationProperties.customFormat = (parseExpression(fieldExpressions.customFormat, values, 'strip')).value;
            }
        }

        if(validationProperties.isRequired !== undefined && validationProperties.isRequiredMessageDynamic){
            validationProperties.isRequiredMessage = (parseExpression(validationProperties.isRequiredMessage, values, 'strip')).value;
        }
        if(validationProperties.hasRegex !== undefined && validationProperties.isRegexMessageDynamic){
            validationProperties.hasRegexMessage = (parseExpression(validationProperties.hasRegexMessage, values, 'strip')).value;
        }
        if(validationProperties.message !== undefined && validationProperties.isMessageDynamic){
            validationProperties.message = (parseExpression(validationProperties.message, values, 'strip')).value;
        }
        if(validationProperties.rangeErrorMessage !== undefined && validationProperties.isRangeErrorMessageDynamic){
            validationProperties.rangeErrorMessage = (parseExpression(validationProperties.rangeErrorMessage, values, 'strip')).value;
        }
        
    }

    parseDynamicLinks (rawValue, values) {
        const linkTag = {
            selector: 'a',
            attribute: 'href'
        };
        const parsedText = parseTagAttribute(linkTag, rawValue, values);
        return parsedText;
    }
};
