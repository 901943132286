import { computed, ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useApplicationStore = defineStore('application', () => {
    const ignoredVariables = ref({});
    const uploadedFiles = ref({});
    const deletedUploads = ref({});

    const getDeletedUploads = computed(() => deletedUploads.value ? Object.keys(deletedUploads.value) : []);

    const updateIgnoredVariable = (id, isValueIgnored) => {
        let variableId = id.value;
        if (id.type === 'field') {
            // if it's a field's ID, try fetching variable ID from field ID
            variableId = Object.keys(ignoredVariables.value).find(key => key.includes(variableId));
        }
        if (variableId && isValueIgnored !== undefined) {
            ignoredVariables.value[variableId] = isValueIgnored;
        }
    };

    return {
        ignoredVariables,
        uploadedFiles,
        deletedUploads,
        getDeletedUploads,
        updateIgnoredVariable
    };
});
