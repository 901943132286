const filtrex = require('filtrex');
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class IfCondition {

    parse(data, values) {
        const { value: leftText } = parseExpression(data.data.leftStatement, values, 'strip');
        const { value: rightText } = parseExpression(data.data.rightStatement, values, 'strip');
        const trueResultText = parseExpression(data.data.trueResult, values, 'unwrap');
        const falseResultText = parseExpression(data.data.falseResult, values, 'unwrap');
        const expression = this.getExpression(leftText, data.data.operator, rightText);
        let evaluator = filtrex.compileExpression(expression);
        if (evaluator({})) {
            return trueResultText;
        }

        return falseResultText;
    }

    getExpression(variable, operation, value) {
        switch (operation) {
        case 'equals':
            return '"' + variable + '"' + ' == ' + '"' + value + '"';
        case 'does not equal':
            return '"' + variable + '"' + ' != ' + '"' + value + '"';
        case 'less than':
            return parseFloat(variable) + ' < ' + parseFloat(value);
        case 'more than':
            return parseFloat(variable) + ' > ' + parseFloat(value);
        case 'less than or equal to':
            return parseFloat(variable) + ' <= ' + parseFloat(value);
        case 'more than or equal to':
            return parseFloat(variable) + ' >= ' + parseFloat(value);
        case 'empty':
            return '"' + variable + '"' + ' == ' + '""';
        case 'not empty':
            return '"' + variable + '"' + ' != ' + '""';
        case 'contains':
            if (variable.includes(value)) {
                return '1 == 1';
            }
            return '1 != 1';
        case 'does not contain':
            if (!variable.includes(value)) {
                return '1 == 1';
            }
            return '1 != 1';
        case 'begins with':
            if (variable.substring(0, value.length) === value) {
                return '1 == 1';
            }
            return '1 != 1';
        case 'ends with':
            if (variable.substring(variable.length - value.length) === value) {
                return '1 == 1';
            }
            return '1 != 1';
        }
    }

};

