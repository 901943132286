import { extractReferenceId, parseExpression } from '@/helpers/ExpressionParser';
import { jsonBuilder } from '@/helpers/JsonBuilder';

export default new class MapArray {
    parse (data, values) {
        const { arrayReference, indexReference, mappingItem } = data.data;

        // parse array
        let { value: rawArrayValue } = parseExpression(arrayReference, values, 'strip');
        if (rawArrayValue.charAt(0) !== '[' && rawArrayValue.charAt(rawArrayValue.length - 1) !== ']') {
            if (rawArrayValue.charAt(0) === '{' && rawArrayValue.charAt(rawArrayValue.length - 1) === '}') {
                rawArrayValue = `[${rawArrayValue.substring(1, rawArrayValue.length - 1)}]`;
            } else {
                // value is primitive, convert to array
                rawArrayValue = `["${rawArrayValue}"]`;
            }
        }
        const parsedArray = JSON.parse(rawArrayValue);

        // parse loop index ID
        const indexReferenceId = extractReferenceId(indexReference);

        const mappedArray = parsedArray.map((_, index) => {
            let mappedItem = null;
            values[indexReferenceId] = `${index}`;
            if (mappingItem.type === 'Object') {
                mappedItem = jsonBuilder.buildObject({}, mappingItem.value, values);
            } else {
                mappedItem = (parseExpression(mappingItem.value, values, 'strip')).value;
            }
            return mappedItem;
        });

        const processedValue = JSON.stringify(mappedArray);
        return {
            value: `{${processedValue.substring(1, processedValue.length - 1)}}`,
            isPlainText: true
        };
    }
};
