import { parseExpression } from './ExpressionParser';
import { unescapeHtml } from './Utils';
const filtrex = require('filtrex');

export default new class ConditionChecker {
    isConditionSatisfied(condition, values) {
        if (!condition || !condition.children || !condition.children.length) {
            return true;
        }

        if (condition.children[0].query.rule === 'always') {
            return true;
        }

        const operator = condition.logicalOperator === 'all' ? ' and ' : ' or ';
        const expression = this.parseQueryToExpression(condition.children, operator, values);
        const evaluator = filtrex.compileExpression(expression);
        return evaluator({});
    }

    parseQueryToExpression(rules, operator, values) {
        let expression = '';
        for (let i = 0; i < rules.length; i++) {
            if (rules[i].type === 'query-builder-rule') {
                let variable = values[rules[i].query.rule];
                let value = this.parseValue(rules[i], values);
                expression += this.parseQueryOperation(variable, rules[i].query.operator, value, operator);
            }
        }
        return expression.slice(0, -4).trim();
    }

    parseValue(rule, values) {
        let value = rule.query?.value ? (parseExpression(rule.query.value, values, 'strip')).value : '';
        if (value === 'null') {
            value = '';
        }
        return value;
    }

    parseQueryOperation(variable, operation, value, operator) {
        value = unescapeHtml(value);
        switch (operation) {
        case 'equals':
            return '"' + variable + '"' + ' == ' + '"' + value + '" ' + operator;
        case 'does not equal':
            return '"' + variable + '"' + ' != ' + '"' + value + '" ' + operator;
        case 'less than':
            return parseFloat(variable) + ' < ' + parseFloat(value) + operator;
        case 'more than':
            return parseFloat(variable) + ' > ' + parseFloat(value) + operator;
        case 'less than or equal to':
            return parseFloat(variable) + ' <= ' + parseFloat(value) + operator;
        case 'more than or equal to':
            return parseFloat(variable) + ' >= ' + parseFloat(value) + operator;
        case 'empty':
            return '"' + (typeof variable === 'undefined' ? '' : variable) + '"' + ' == ' + '""' + operator;
        case 'not empty':
            return '"' + (typeof variable === 'undefined' ? '' : variable) + '"' + ' != ' + '""' + operator;
        case 'contains':
            if (variable?.includes(value)) {
                return '1 == 1' + operator;
            }
            return '1 != 1' + operator;
        case 'does not contain':
            if (!variable?.includes(value)) {
                return '1 == 1' + operator;
            }
            return '1 != 1 ' + operator;
        case 'begins with':
            if (variable?.substring(0, value.length) === value) {
                return '1 == 1' + operator;
            }
            return '1 != 1 ' + operator;
        case 'ends with':
            if (variable?.substring(variable.length - value.length) === value) {
                return '1 == 1' + operator;
            }
            return '1 != 1 ' + operator;
        }
    }
};