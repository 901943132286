const moment = require('moment-timezone');
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class DateArithmeticCalculation {
    parse(data, values) {
        let first_date, second_date;
        // parse units
        const firstDateUnits = data.data.first_date?.units;
        const secondDateUnits = data.data.second_date?.units;
        if (firstDateUnits && typeof firstDateUnits === 'string') {
            data.data.first_date.units = (parseExpression(firstDateUnits, values, 'strip')).value;
        }
        if (secondDateUnits && typeof secondDateUnits === 'string') {
            data.data.second_date.units = (parseExpression(secondDateUnits, values, 'strip')).value;
        }
        if (data.data.first_date.type === 'Custom') {
            const { value } = parseExpression(data.data.first_date.date, values, 'strip');
            first_date = moment.tz(value, data.data.first_date.timezone);
        } else {
            if (data.data.first_date.type === 'Yesterday') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(1, 'days');
            } else if (data.data.first_date.type === 'Tomorrow') {
                first_date = moment.tz(data.data.first_date.timezone).add(1, 'days');
            } else if (data.data.first_date.type === 'Days from today') {
                first_date = moment.tz(data.data.first_date.timezone).add(data.data.first_date.units, 'days');
            } else if (data.data.first_date.type === 'Days before today') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(data.data.first_date.units, 'days');
            } else if (data.data.first_date.type === 'Months from today') {
                first_date = moment.tz(data.data.first_date.timezone).add(data.data.first_date.units, 'months');
            } else if (data.data.first_date.type === 'Months before today') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(data.data.first_date.units, 'months');
            } else if (data.data.first_date.type === 'Years from today') {
                first_date = moment.tz(data.data.first_date.timezone).add(data.data.first_date.units, 'years');
            } else if (data.data.first_date.type === 'Years before today') {
                first_date = moment.tz(data.data.first_date.timezone).subtract(data.data.first_date.units, 'years');
            } else {
                first_date = moment.tz(data.data.first_date.timezone);
            }
        }

        if (data.data.second_date.type === 'Custom') {
            const { value } = parseExpression(data.data.second_date.date, values, 'strip');
            second_date = moment.tz(value, data.data.second_date.timezone);
        } else {
            if (data.data.second_date.type === 'Yesterday') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(1, 'days');
            } else if (data.data.second_date.type === 'Tomorrow') {
                second_date = moment.tz(data.data.second_date.timezone).add(1, 'days');
            } else if (data.data.second_date.type === 'Days from today') {
                second_date = moment.tz(data.data.second_date.timezone).add(data.data.second_date.units, 'days');
            } else if (data.data.second_date.type === 'Days before today') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(data.data.second_date.units, 'days');
            }  else if (data.data.second_date.type === 'Months from today') {
                second_date = moment.tz(data.data.second_date.timezone).add(data.data.second_date.units, 'months');
            } else if (data.data.second_date.type === 'Months before today') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(data.data.second_date.units, 'months');
            } else if (data.data.second_date.type === 'Years from today') {
                second_date = moment.tz(data.data.second_date.timezone).add(data.data.second_date.units, 'years');
            } else if (data.data.second_date.type === 'Years before today') {
                second_date = moment.tz(data.data.second_date.timezone).subtract(data.data.second_date.units, 'years');
            } else if (data.data.second_date.type === 'Days from first date') {
                second_date = moment.tz(first_date, data.data.second_date.timezone).add(data.data.second_date.units, 'days');
            } else if (data.data.second_date.type === 'Days before first date') {
                second_date = moment.tz(first_date, data.data.second_date.timezone).subtract(data.data.second_date.units, 'days');
            } else if (data.data.second_date.type === 'Months from first date') {
                second_date = moment.tz(first_date, data.data.second_date.timezone).add(data.data.second_date.units, 'months');
            } else if (data.data.second_date.type === 'Months before first date') {
                second_date = moment.tz(first_date, data.data.second_date.timezone).subtract(data.data.second_date.units, 'months');
            } else if (data.data.second_date.type === 'Years from first date') {
                second_date = moment.tz(first_date, data.data.second_date.timezone).add(data.data.second_date.units, 'years');
            } else if (data.data.second_date.type === 'Years before first date') {
                second_date = moment.tz(first_date, data.data.second_date.timezone).subtract(data.data.second_date.units, 'years');
            } else {
                second_date = moment.tz(data.data.second_date.timezone);
            }
        }

        if (data.data.result.type === 'Date') {
            return {
                value: second_date.format(data.data.result.format),
                isPlainText: true
            };
        }

        return {
            value: first_date.diff(second_date, data.data.result.units.toLowerCase()),
            isPlainText: true
        };
    }
};
