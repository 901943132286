const FormulaParser = require('hot-formula-parser').Parser;
import { parseExpression } from '@/helpers/ExpressionParser';

export default new class ArithmeticCalculation {
    constructor() {
        this.formula_parser = new FormulaParser();
    }

    parse(data, values) {
        const parsedExpression = parseExpression(data.data.formula, values, 'strip');
        let parsedFormula = this.formula_parser.parse(parsedExpression.value);
        const parsedValue = {
            value: '',
            isPlainText: parsedExpression.isPlainText
        };
        if (!parsedFormula.error) {
            parsedValue.value = parsedFormula.result;
        }
        return parsedValue;
    }
};
